<template>
  <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEditing ? 'Edit Tour' : 'Add New Tour' }}</h5>
          <button type="button" class="close" aria-label="Close" @click="handleClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="tourName">Tour Name</label>
                <input type="text" class="form-control" id="tourName" v-model="form.tourName" required />
              </div>

              <div class="col-md-6 form-group">
                <label for="cruiseLine">Cruise Lines</label>
                <select class="form-control" id="cruiseLine" v-model="form.cruiseLine">
                  <option v-for="line in cruiseLineOptions" :key="line" :value="line">{{ line }}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 form-group">
                <label for="port">Port</label>
                <select class="form-control" id="port" v-model="selectedPort">
                  <option v-for="city in availableCities" :key="city.id" :value="city.id">
                    {{ city.attributes.city }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 form-group">
                <label for="tourCode">Tour Code</label>
                <input type="text" class="form-control" id="tourCode" v-model="form.tourCode" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 form-group">
                <label for="cities">Select City</label>
                <select class="form-control" id="cities" v-model="selectedCity">
                  <option v-for="city in availableCities" :key="city.id" :value="city.id">
                    {{ city.attributes.city }}
                  </option>
                </select>
                <button type="button" class="btn btn-secondary mt-2" @click="addCity">+</button>
                <div class="form-group">
                  <label>Selected Cities</label>
                  <ul>
                    <li v-for="city in selectedCities" :key="city" class="city-item">
                      {{ getCityName(city) }}
                      <b-button size="sm" class="remove-btn" @click="removeCity(city)">
                        <i class="fas fa-trash"></i>
                </b-button>
                    </li>
                  </ul>
                </div>
                <div v-if="cityError" class="text-danger">Please select at least one city.</div>
              </div>

              <div class="col-md-6 form-group">
                <label for="year">Year</label>
                <input type="text" class="form-control" id="year" v-model="form.year" />
              </div>

              <!-- <div class="col-md-6 form-group">
                <label for="items">Select Item</label>
                <select class="form-control" id="items" v-model="selectedItem">
                  <option v-for="item in availableItems" :key="item.id" :value="item.id">
                    {{ item.attributes.item }}
                  </option>
                </select>
                <button type="button" class="btn btn-secondary mt-2" @click="addItem">+</button>
                <div class="form-group">
                  <label>Selected Items</label>
                  <ul>
                    <li v-for="item in selectedItems" :key="item">{{ getItemName(item) }}</li>
                  </ul>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-md-4 form-group">
                <label for="duration">Duration</label>
                <input type="text" class="form-control" id="duration" v-model="form.duration" />
              </div>

              <div class="col-md-4 form-group">
                <label for="paxMin">Pax Min</label>
                <input type="number" class="form-control" id="paxMin" v-model="form.paxMin" />
              </div>

              <div class="col-md-4 form-group">
                <label for="paxMax">Pax Max</label>
                <input type="number" class="form-control" id="paxMax" v-model="form.paxMax" />
              </div>

              <div class="col-md-6 form-group">
                <label for="timing">Timing</label>
                <textarea type="textarea" class="form-control" id="timing" v-model="form.timing"></textarea>
              </div>

              <div class="col-md-6 form-group">
                <label for="description">Description</label>
                <textarea type="textarea" class="form-control" id="description" v-model="form.description"></textarea>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Submit' }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tour: Object 
  },
  data() {
    return {
      form: {
        tourName: '',
        cruiseLine: '',
        port: '',
        tourCode: '',
        year:'',
        duration: '',
        paxMin: '',
        paxMax: '',
        timing: '',
        description: '',
      },
      availableItems: [],
      selectedItems: [],
      availableCities: [],
      selectedCities: [],
      selectedPort: null,
      selectedCity: null,
      selectedItem: null,
      cruiseLineOptions: [
        'Norwegian Cruise Line', 'Princess Cruises', 'Azamara Cruises', 'Celebrity Cruises',
        'Compagnie Francaise de Croisieres', 'Crystal Cruises', 'Cunard', 'Disney Cruise Line',
        'Fred Olsen Cruise Lines', 'Holland America Line', 'Lindblad Expeditions', 'MSC Cruises',
        'Mystic Cruises', 'P&O Cruises', 'Regent Seven Seas Cruises', 'Virgin Voyages',
        'Windstar Cruises', 'Yachts of Seabourn', 'Oceania Cruises', 'Royal Caribbean International',
        'Viking Ocean Cruises'
      ],
      cityError: false,
    };
  },
  computed: {
    isEditing() {
      return !!this.tour; 
    }
  },
  watch: {
    tour: {
      immediate: true,
      handler(tour) {
        if (tour) {
          this.form = {
            tourName: tour.attributes.tourName,
            cruiseLine: tour.attributes.cruiseLine,
            tourCode: tour.attributes.tourCode,
            year: tour.attributes.year,
            duration: tour.attributes.duration,
            paxMin: tour.attributes.paxMin,
            paxMax: tour.attributes.paxMax,
            timing: tour.attributes.timing,
            description: tour.attributes.description,
          };
          this.selectedPort = tour.attributes.port?.data?.id;
          this.selectedCities = tour.attributes.cities?.data.map(city => city.id);
          this.selectedItems = tour.attributes.item?.data.map(item => item.id);
        }
      }
    }
  },
  async created() {
    await this.fetchItems();
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async fetchItems() {
  const [itemsResponse, citiesResponse] = await Promise.all([
    this.$xhr.get('/api/services?populate=*'),
    this.$xhr.get('/api/cities?populate=*')
  ]);

  this.availableItems = itemsResponse.data.data;
  this.availableCities = citiesResponse.data.data.sort((a, b) => 
    a.attributes.city.localeCompare(b.attributes.city)
  );
},

    addCity() {
      if (this.selectedCity && !this.selectedCities.includes(this.selectedCity)) {
        this.selectedCities.push(this.selectedCity);
        this.selectedCity = null;
        this.cityError = false;
      } else if (!this.selectedCities.length) {
        this.cityError = true;
      }
    },
    removeCity(cityId) {
      this.selectedCities = this.selectedCities.filter(id => id !== cityId);
    },
    addItem() {
      if (this.selectedItem && !this.selectedItems.includes(this.selectedItem)) {
        this.selectedItems.push(this.selectedItem);
        this.selectedItem = null;
      }
    },
    getCityName(id) {
      const city = this.availableCities.find(c => c.id === id);
      return city ? city.attributes.city : 'Unknown City';
    },
    getItemName(id) {
      const item = this.availableItems.find(i => i.id === id);
      return item ? item.attributes.item : 'Unknown Item';
    },
    async submitForm() {
      if (this.selectedCities.length === 0) {
        this.cityError = true;
        return;
      }

      const existingTours = await this.$xhr.get(`/api/tours?filters[tourCode][$eq]=${this.form.tourCode}`);
      if (existingTours.data.data.length > 0 && !this.isEditing) {
        this.$bvToast.toast('A tour with the same code already exists.', {
          title: 'Duplicate Tour Code',
          variant: 'danger',
          solid: true
        });
        return;
      }

      const payload = {
        data: {
          tourName: this.form.tourName,
          cruiseLine: this.form.cruiseLine,
          port: { id: this.selectedPort },
          tourCode: this.form.tourCode,
          year:this.form.year,
          cities: this.selectedCities.map(id => ({ id })),
          duration: this.form.duration,
          paxMin: this.form.paxMin,
          paxMax: this.form.paxMax,
          timing: this.form.timing,
          description: this.form.description,
          item: this.selectedItems.map(id => ({ id }))
        }
      };

      if (this.isEditing) {
        await this.$xhr.put(`/api/tours/${this.tour.id}`, payload);
      } else {
        await this.$xhr.post('/api/tours', payload);
      }

      this.$emit('tour-added');
      this.handleClose();
    }
  }
};
</script>

<style scoped>
.modal-content {
  margin: 10px auto;
}

.modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 50%;
}
.remove-btn {
  background-color: red;
  color: white;
  margin-left: 1rem; 
}
.city-item {
  margin-bottom: 0.5rem;
}

</style>
