import { createRouter, createWebHashHistory } from 'vue-router';
import Home from './pages/Home.vue';
import Login from '@lib/frontend/pages/Login.vue';
import Ocean from './components/Ocean.vue';
import Uk from './components/Uk.vue';
import Editocean from './components/Editocean.vue';
import Edituk from './components/Edituk.vue';
import Calender from './components/Calender.vue';
import Oceancalender from './components/Oceancalender.vue';
import Calculation from './Calculation/Calculation.vue';
import ServiceList from './Calculation/ServiceList.vue';
import TourList from './Calculation/TourList.vue';
import Audit from './Calculation/Audit.vue';
import TourDetails from './Calculation/TourDetails.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/ocean',
    name: 'Ocean',
    component: Ocean
  },
  {
    path: '/uk',
    name: 'Uk',
    component: Uk
  },
  {
    path: '/ocean/edit/:id',
    name: 'Editocean',
    component: Editocean
  },
  {
    path: '/uk/edit/:id',
    name: 'Edituk',
    component: Edituk
  },
  {
    path: '/calender',
    name: 'Calender',
    component: Calender
  },
  {
    path: '/oceancalender',
    name: 'Oceancalender',
    component: Oceancalender
  },
  {
    path: '/calculation',
    name: 'Calculation',
    component: Calculation,
    children: [
      {
        path: 'tourlist',
        components: { default: TourList }
      },
      {
        path: 'itemlist',
        components: { default: ServiceList }
      },
      {
        path: 'audit',
        components: { default: Audit }
      }
    ]
  },
  {
    path: '/tour/:id',
    name: 'TourDetails',
    component: TourDetails,
    props: true
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
