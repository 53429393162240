<template>
  <Header />
  <div class="head">
    <h1>Shorex Allotments Germany </h1>
  </div><br>

  <div class="imp">
    <b-button class="" variant="success" v-on:click="generateChart()">Plot</b-button>
    <b-button class="" variant="warning" v-on:click="resetFilters()">Reset</b-button>
    <b-button class="" variant="primary" v-on:click="showImportModal = true">Import</b-button>
    <b-button class="" variant="danger" v-on:click="deleteAllData()">Delete All</b-button>

  </div>

  <select v-model="selectedYear" id="selectedYear" class="form-control">
    <option value="" disabled selected>Select Year</option>
    <template v-for="year in allYears" :key="year">
      <option :value="year">{{ year }}</option>
    </template>
  </select>
  <div class="d-flex">
    <select v-model="selectedDate" id="selectedDate" class="form-control">
      <option value="" disabled selected>Shorex Date</option>
      <template v-for="date in allDates" :key="date">
        <option :value="date">{{ date }}</option>
      </template>
    </select>
    <span v-if="selectedDate" class="clear-1" @click="clearSelectedDate">&times;</span>

    <select v-model="selectedUpdateDate" id="selectedUpdateDate" class="form-control">
      <option value="" disabled selected>Update Date</option>
      <template v-for="updateDate in allUpdateDates" :key="updateDate">
        <option :value="updateDate">{{ updateDate }}</option>
      </template>
    </select>
    <span v-if="selectedUpdateDate" class="clear-2" @click="clearSelectedUpdateDate">&times;</span>


    <select v-model="selectedCruiseline" id="selectedCruiseline" class="form-control">
      <option value="" disabled selected>Cruise Line</option>
      <template v-for="cruiseline in allCruiselines" :key="cruiseline">
        <option :value="cruiseline">{{ cruiseline }}</option>
      </template>
    </select>
    <span v-if="selectedCruiseline" class="clear-3" @click="clearSelectedCruiseline">&times;</span>

    <select v-model="selectedTourship" id="selectedTourship" class="form-control">
      <option value="" disabled selected>Ship</option>
      <template v-for="tourship in allTourships" :key="tourship">
        <option :value="tourship">{{ tourship }}</option>
      </template>
    </select>
    <span v-if="selectedTourship" class="clear-4" @click="clearSelectedTourship">&times;</span>

    <select v-model="selectedTourname" id="selectedTourname" class="form-control">
      <option value="" disabled selected>Tour Name</option>
      <template v-for="tourname in allTournames" :key="tourname">
        <option :value="tourname">{{ tourname }}</option>
      </template>
    </select>
    <span v-if="selectedTourname" class="clear-5" @click="clearSelectedTourname">&times;</span>

    <select v-model="selectedTourcode" id="selectedTourcode" class="form-control">
      <option value="" disabled selected>Tour Code</option>
      <template v-for="tourcode in allTourcodes" :key="tourcode">
        <option :value="tourcode">{{ tourcode }}</option>
      </template>
    </select>
    <span v-if="selectedTourcode" class="clear-6" @click="clearSelectedTourcode">&times;</span>

    <select v-model="selectedMxpcode" id="selectedMxpcode" class="form-control">
      <option value="" disabled selected>Mxp Code</option>
      <template v-for="mxpcode in allMxpcodes" :key="mxpcode">
        <option :value="mxpcode">{{ mxpcode }}</option>
      </template>
    </select>
    <span v-if="selectedMxpcode" class="clear-7" @click="clearSelectedMxpcode">&times;</span>
    <input v-model="filter" type="text" placeholder="Search" class="form-control" />


  </div>


  <div>
    <b-table striped hover :items="filteredListByCruiseline" :fields="[...fields, { key: 'actions', label: 'CRUD' }]"
         :filter="filter" v-model:sort-by="sortBy" v-model:sort-desc="sortDesc">
  <template #cell(actions)="row">
    <b-button variant="info" size="sm" class="p-0" :to="{ name: 'Editocean', params: { id: row.item.id } }">
      Edit
    </b-button>
    <b-button variant="danger" size="sm" class="p-0" v-on:click="deleteData(row.item.id)">
          Del
        </b-button>
  </template>
</b-table>

  </div>

  <b-modal v-model="showImportModal" title="Import Data">
    <div class="d-flex justify-content-center align-items-center">
      <input type="file" ref="fileInput" v-on:change="onFileChange($event)" accept=".xlsx,.xls,.csv" />
    </div>
  </b-modal>
</template>

<script>
import Header from './Header.vue'
import * as XLSX from 'xlsx'
import Chart from 'chart.js/auto';
export default {
  name: 'Ocean',
  components: {
    Header,
  },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      sortBy: '',
      sortDesc: false,
      selectedDate: '',
      selectedUpdateDate: '',
      selectedCruiseline: '',
      selectedTourship: '',
      selectedTourname: '',
      selectedTourcode: '',
      selectedMxpcode: '',
      selectedYear: currentYear, 
      loading: false,
      list: undefined,
      showImportModal: false,
      filter: '',
      fields: [
        { key: 'attributes.date', label: 'Shorex Date', sortable: true },
        {
          key: 'attributes.todaydate', label: 'Update Date', sortable: true, formatter: (value) => {
            const date = new Date(value);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          }
        },
        { key: 'attributes.tourship', label: 'Ship', sortable: true },
        { key: 'attributes.cruiseline', label: 'Cruise Line', sortable: true },
        { key: 'attributes.tourcode', label: 'Tour Code', sortable: true },
        { key: 'attributes.mxpcode', label: 'MXP Code', sortable: true },
        { key: 'attributes.tourname', label: 'Tour Name', sortable: true },
        { key: 'attributes.maxcapacity', label: 'Max Capacity', sortable: true },
        { key: 'attributes.totalsold', label: 'Total Sold', sortable: true },
        { key: 'attributes.fromtime', label: 'From Time', sortable: true },
        { key: 'attributes.totime', label: 'To Time', sortable: true },
        { key: 'attributes.status', label: 'Status', sortable: true },
      ]

    }
  },
  computed: {
    allDates() {
      if (!this.list) return []

      const dates = new Set(this.list.map(item => item.attributes.date).sort())
      return Array.from(dates)
    },
    allUpdateDates() {
      if (!this.list) return []

      const todaydates = new Set(this.list.map(item => item.attributes.todaydate).sort())
      return Array.from(todaydates)
    },
    allCruiselines() {
      if (!this.list) return []

      const cruiselines = new Set(this.list.map(item => item.attributes.cruiseline).sort())
      return Array.from(cruiselines)
    },
    allTournames() {
      if (!this.list) return []

      const tournames = new Set(this.list.map(item => item.attributes.tourname).sort())
      return Array.from(tournames)
    },
    allTourships() {
      if (!this.list) return []

      const tourships = new Set(this.list.map(item => item.attributes.tourship).sort())
      return Array.from(tourships)
    },
    allTourcodes() {
      if (!this.list) return []

      const tourcode = new Set(this.list.map(item => item.attributes.tourcode).sort())
      return Array.from(tourcode)
    },
    allMxpcodes() {
      if (!this.list) return []

      const mxpcodes = new Set(this.list.map(item => item.attributes.mxpcode).sort())
      return Array.from(mxpcodes)
    },
    allUpdateYears() {
      if (!this.list) return [];

      const years = new Set(this.list.map(item => new Date(item.attributes.todaydate).getFullYear()).sort());
      return Array.from(years);
    },
    allYears() {
      return this.allUpdateYears;
    },
    filteredList() {
      if (!this.list) return this.list;

      let filteredList = this.list;

      if (this.selectedDate) {
        filteredList = filteredList.filter(
          item => item.attributes.date === this.selectedDate
        );
      }

      if (this.selectedUpdateDate) {
        filteredList = filteredList.filter(
          item => item.attributes.todaydate === this.selectedUpdateDate
        );
      }

      if (this.selectedCruiseline) {
        filteredList = filteredList.filter(
          item => item.attributes.cruiseline === this.selectedCruiseline
        );
      }

      if (this.selectedTourname) {
        filteredList = filteredList.filter(
          item => item.attributes.tourname === this.selectedTourname
        );
      }

      if (this.selectedTourship) {
        filteredList = filteredList.filter(
          item => item.attributes.tourship === this.selectedTourship
        );
      }

      if (this.selectedTourcode) {
        filteredList = filteredList.filter(
          item => item.attributes.tourcode === this.selectedTourcode
        );
      }
      if (this.selectedMxpcode) {
        filteredList = filteredList.filter(
          item => item.attributes.mxpcode === this.selectedMxpcode
        );
      }
      if (this.selectedYear) {
        filteredList = filteredList.filter(
          item => new Date(item.attributes.todaydate).getFullYear() === parseInt(this.selectedYear)
        );
      }

      return filteredList;
    },

    filteredListByCruiseline() {
      if (!this.filteredList || !this.selectedCruiseline) {
        return this.filteredList;
      }
      return this.filteredList.filter(
        item => item.attributes.cruiseline === this.selectedCruiseline
      );
    }


  },
  methods: {
    clearSelectedDate() {
      this.selectedDate = ''
    },
    clearSelectedUpdateDate() {
      this.selectedUpdateDate = ''
    },
    clearSelectedCruiseline() {
      this.selectedCruiseline = ''
    },
    clearSelectedTourship() {
      this.selectedTourship = ''
    },
    clearSelectedTourname() {
      this.selectedTourname = ''
    },
    clearSelectedTourcode() {
      this.selectedTourcode = ''
    },
    clearSelectedMxpcode() {
      this.selectedMxpcode = ''
    },
    sort(field) {
      // if already sorting by this field, toggle sort direction
      if (this.sortBy === field) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = field;
        this.sortDesc = false;
      }

      // perform the actual sorting
      this.filteredListByCruiseline.sort((a, b) => {
        const valueA = this.getPropertyValue(a, field);
        const valueB = this.getPropertyValue(b, field);

        if (valueA < valueB) return this.sortDesc ? 1 : -1;
        if (valueA > valueB) return this.sortDesc ? -1 : 1;
        if (valueA === valueB) return 0;
        if (field === "shipName") {
          const cruiseLineA = this.getPropertyValue(a, "cruiseLineName");
          const cruiseLineB = this.getPropertyValue(b, "cruiseLineName");
          if (cruiseLineA < cruiseLineB) return this.sortDesc ? 1 : -1;
          if (cruiseLineA > cruiseLineB) return this.sortDesc ? -1 : 1;
        }

        // default to sorting by the field itself
        return this.sortDesc ? 1 : -1;
      });
    },
    getPropertyValue(obj, field) {
      // helper function to get the property value from nested objects
      const keys = field.split(".");
      let value = obj;
      for (let i = 0; i < keys.length; i++) {
        value = value[keys[i]];
      }
      return value;
    },
    selectDate(date) {
      this.selectedDate = date
    },
    selectUpdateDate(updateDate) {
      this.selectedUpdateDate = updateDate;
      this.selectedYear = new Date(updateDate).getFullYear().toString();
    },
    selectCruiseline(cruiseline) {
      this.selectedCruiseline = cruiseline

    },
    selectTourname(tourname) {
      this.selectedTourname = tourname
    },
    selectTourship(tourship) {
      this.selectedTourship = tourship
    },
    selectTourcode(tourcode) {
      this.selectedTourcode = tourcode
    },
    selectMxpcode(mxpcode) {
      this.selectedMxpcode = mxpcode
    },
    resetFilters() {
      this.selectedDate = '';
      this.selectedUpdateDate = '';
      this.selectedCruiseline = '';
      this.selectedTourname = '';
      this.selectedTourship = '';
      this.selectedTourcode = '';
      this.selectedMxpcode = '';
    },
    deleteAllData() {
      if (confirm('Are you sure you want to delete all entries?')) {
        this.filteredListByCruiseline.forEach(item => {
          this.deleteData(item.id);
        });
      }
    },
    generateChart() {
      // Filter the data based on the selected filters
      const filteredData = this.list.filter((item) => {
        return (!this.selectedDate || item.attributes.date === this.selectedDate) &&
          (!this.selectedUpdateDate || item.attributes.todaydate === this.selectedUpdateDate) &&
          (!this.selectedCruiseline || item.attributes.cruiseline === this.selectedCruiseline) &&
          (!this.selectedTourship || item.attributes.tourship === this.selectedTourship) &&
          (!this.selectedTourcode || item.attributes.tourcode === this.selectedTourcode) &&
          (!this.selectedMxpcode || item.attributes.mxpcode === this.selectedMxpcode) &&
          (!this.selectedTourname || item.attributes.tourname === this.selectedTourname) &&
          (!this.selectedYear || new Date(item.attributes.todaydate).getFullYear() === parseInt(this.selectedYear));
      }).sort((a, b) => {
        // Sort by todaydate in descending order
        if (a.attributes.todaydate < b.attributes.todaydate) return 1;
        if (a.attributes.todaydate > b.attributes.todaydate) return -1;
        return 0;
      });

      // Extract the relevant data for the chart
      const uniqueTourNameTimes = [...new Set(filteredData.map(item => `${item.attributes.tourname} (${item.attributes.fromtime})`))];
      const uniqueDates = [...new Set(filteredData.map(item => item.attributes.todaydate))].sort();
      const datasets = uniqueTourNameTimes.map((tourNameTime, index) => {
        const [tourName, fromTime] = tourNameTime.split(' (');
        const filteredItems = filteredData.filter(item => item.attributes.tourname === tourName && item.attributes.fromtime === fromTime.slice(0, -1));
        const data = filteredItems.map(item => ({ x: item.attributes.todaydate, y: item.attributes.totalsold }));
        const label = tourNameTime;
        return {
          label: label,
          data: data,
          borderColor: ['blue', 'red', 'green', 'orange', 'purple', 'brown', 'yellow', 'lime', 'black', 'cadetblue',
            'aqua', 'bisque', 'blueviolet', 'chocolate', 'coral', 'darkgreen', 'gold', 'cyan', 'darkcyan',
            'darkblue', 'firebrick', 'indigo', 'khaki', 'lightseagreen', 'lightskyblue', 'maroon', 'peru',
            'plum', 'wheat', 'fuchsia', 'lavender', 'olive', 'salmon', 'teal', 'magenta', 'turquoise', 'navy',
            'slate gray', 'hot pink', 'chartreuse', 'goldenrod', 'rosy brown', 'cornflower blue', 'deep pink'][index],
          fill: false,
        };
      });
      const labels = uniqueDates;

      // Construct the filter legend text
      const filterLegend = [];
      if (this.selectedDate) {
        filterLegend.push(`Date: ${this.selectedDate}`);
      }
      if (this.selectedCruiseline) {
        filterLegend.push(`Cruiseline: ${this.selectedCruiseline}`);
      }
      if (this.selectedTourship) {
        filterLegend.push(`Tourship: ${this.selectedTourship}`);
      }
      if (this.selectedTourname) {
        filterLegend.push(`Tourname: ${this.selectedTourname}`);
      }
      if (this.selectedTourcode) {
        filterLegend.push(`Tourcode: ${this.selectedTourcode}`);
      }
      if (this.selectedMxpcode) {
        filterLegend.push(`Mxpcode: ${this.selectedMxpcode}`);
      }


      // Add the filter legend text to the chart title
      const chartTitle = `Updated Date vs Total Sold (${filterLegend.join(', ')})`;

      // Create a new window for the chart
      const chartWindow = window.open('', '', 'width=800,height=600');

      // Add the canvas element for the chart to the new window
      chartWindow.document.write('<html><head><title>Chart Window</title></head><body><canvas id="chartCanvas"></canvas></body></html>');

      // Retrieve the canvas
      const chartCanvas = chartWindow.document.getElementById('chartCanvas');

      // Set the canvas dimensions
      chartCanvas.width = 800;
      chartCanvas.height = 500;

      // Create the chart
      const chart = new Chart(chartCanvas, {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date and time over the year',
                color: 'black',
                font: {
                  size: 18
                }
              }
            },
            y: {
              title: {
                display: true,
                text: 'Total Sold',
                color: 'black',
                font: {
                  size: 18
                }
              }
            }
          },

        }
      });
      // Add the selected filters to the new window
      const filterList = chartWindow.document.createElement('ul');
      if (this.selectedDate) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Shorex Date: ${this.selectedDate}`;
        filterList.appendChild(filterItem);
      }
      if (this.selectedCruiseline) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Cruiseline: ${this.selectedCruiseline}`;
        filterList.appendChild(filterItem);
      }
      if (this.selectedTourship) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Tourship: ${this.selectedTourship}`;
        filterList.appendChild(filterItem);
      }
      if (this.selectedTourname) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Tourname: ${this.selectedTourname}`;
        filterList.appendChild(filterItem);
      }
      if (this.selectedTourcode) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Tourcode: ${this.selectedTourcode}`;
        filterList.appendChild(filterItem);
      }
      if (this.selectedMxpcode) {
        const filterItem = chartWindow.document.createElement('li');
        filterItem.textContent = `Mxpcode: ${this.selectedMxpcode}`;
        filterList.appendChild(filterItem);
      }
      chartWindow.document.body.appendChild(filterList);

    },

    async deleteData(id) {
      if (confirm('Are you sure you want to delete this entry?')) {
        await this.$xhr.delete('/api/oceans/' + id);
        this.getData();
      }
    },
    async getData() {
      const resp = await this.$xhr.get('/api/oceans?populate=*');
      this.list = resp.data.data;
      console.log(this.list,'aaaa')
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary', cellText: true, cellDates: true, dateNF: 'yyyy-mm-dd;@', WTF: 'utc' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { dateNF: 'yyyy-mm-dd;@' });

        jsonData.forEach((row) => { row.date = new Date(row.date.getTime() + 24 * 60 * 60 * 1000) });

        this.loading = true;

        for (const row of jsonData) {
          await this.$xhr.post('/api/oceans', { data: row });
        }

        this.loading = false;
        this.showImportModal = false;
        this.getData();
      };

      reader.readAsBinaryString(file);
    },
  },
  async mounted() {
    await this.getData();
  }
}
</script>

