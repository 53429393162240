<template>
  <router-view />
  <Header/>
</template>

<script>
import Header from './components/Header.vue'
import { computed, inject } from 'vue'
export default {
  components: {
    Header},
  name: 'App',
  setup(){
    const {profile} = inject('user')
    return {
      profile
    }
  },
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
