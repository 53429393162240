<template>
  <Header />

  <form class="add container mt-4">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Date</label>
          <input type="date" class="form-control" v-model="data.attributes.date" />
        </div>
        <div class="form-group">
          <label>Updated Date</label>
          <input type="date" class="form-control" v-model="data.attributes.todaydate" />
        </div>
        <div class="form-group">
          <label>Tour Ship</label>
          <input type="text" class="form-control" v-model="data.attributes.tourship" />
        </div>
        <div class="form-group">
          <label>Cruise Line</label>
          <input type="text" class="form-control" v-model="data.attributes.cruiseline" />
        </div>
        <div class="form-group">
          <label>Tour Code</label>
          <input type="text" class="form-control" v-model="data.attributes.tourcode" />
        </div>
        <div class="form-group">
          <label>Mxp Code</label>
          <input type="text" class="form-control" v-model="data.attributes.mxpcode" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Tour Name</label>
          <input type="text" class="form-control" v-model="data.attributes.tourname" />
        </div>
        <div class="form-group">
          <label>Max Capacity</label>
          <input type="float" class="form-control" v-model="data.attributes.maxcapacity" />
        </div>
        <div class="form-group">
          <label>Total Sold</label>
          <input type="float" class="form-control" v-model="data.attributes.totalsold" />
        </div>
        <div class="form-group">
          <label>From Time</label>
          <input type="time" class="form-control" v-model="data.attributes.fromtime" />
        </div>
        <div class="form-group">
          <label>To Time</label>
          <input type="time" class="form-control" v-model="data.attributes.totime" />
        </div>
        <div class="form-group">
          <label>Status</label>
          <input type="text" class="form-control" v-model="data.attributes.status" />
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-primary" v-on:click="editCruise">Update</button>
  </form>
</template>


<script>
// @ is an alias to /src
import Header from './Header.vue'

export default {
  name: 'Edituk',
  components: {
    Header
  },
  data() {
    return {
      data: {
        attributes: {
          date: "",
          todaydate: "",
          tourship: "",
          cruiseline: "",
          tourcode: "",
          mxpcode: "",
          tourname: "",
          maxcapacity: "",
          totalsold: "",
          fromtime: "",
          totime: "",
          status: "",



        }


      }
    }
  },

  methods: {
    async editCruise() {
      console.log(this.data)
      const result = await this.$xhr.put('/api/uks/' + this.$route.params.id, {
        data: {
          date: this.data.attributes.date,
          todaydate: this.data.attributes.todaydate,
          tourship: this.data.attributes.tourship,
          cruiseline: this.data.attributes.cruiseline,
          tourcode: this.data.attributes.tourcode,
          mxpcode: this.data.attributes.mxpcode,
          tourname: this.data.attributes.tourname,
          maxcapacity: this.data.attributes.maxcapacity,
          totalsold: this.data.attributes.totalsold,
          fromtime: this.data.attributes.fromtime,
          totime: this.data.attributes.totime,
          status: this.data.attributes.status,

        }
      });
      if (result.status == 200) {
        this.$router.push({ name: 'Uk' })
      }

    }
  },
  async mounted() {
    let user = localStorage.getItem('jwt');
    if (!user) {
      this.$router.push({ name: 'Uk' })
    }
    const result = await this.$xhr.get('/api/uks/' + this.$route.params.id)
    console.log(result.data.data)
    this.data.attributes = result.data.data.attributes
  }
}
</script>
<style></style>