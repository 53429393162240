<template>
  <div>
    <b-card  title="Tour Overview"> 
     <TourTable />
    <TourCalculation />
    </b-card>
       
  </div>
</template>


<script>
import TourTable from './TourTable.vue';
import TourCalculation from './TourCalculation.vue';

export default {
  components: {
    TourTable,
    TourCalculation
  },
};
</script>



