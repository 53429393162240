<template>
  <div>
    <Header />
    <div class="calendar-container">
      <FullCalendar :options="calendarOptions" ref="calendar" />
    </div>
    <div class="imp">
      <b-button variant="success" @click="changeView('week')">Weekly View</b-button>
      <b-button variant="primary" @click="changeView('month')">Monthly View</b-button>
      <b-button @click="downloadPDF">Download PDF</b-button>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import weekGridPlugin from '@fullcalendar/timegrid'

import 'fullcalendar/dist/fullcalendar.css'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import { BIconSlack } from 'bootstrap-vue'

export default {
  name: 'Calender',
  components: {
    Header,
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, weekGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        views: {
          month: {
            type: 'dayGridMonth',
            duration: { months: 1 }
          },
          week: {
            type: 'dayGridWeek',
            duration: { weeks: 2 }
          }
        },
        dateClick: this.handleDateClick,
        events: []
      }
    }
  },
  created() {
    this.$xhr.get('calender.json').then(response => {
      const shipSchedules = response.data.slice(1) // ignore the first empty object

      // Define ship colors
      const shipColors = {
        'AmaPrima': 'red', 
        'AmaSiena': 'green',
        'AmaSerena': 'lightsalmon',
        'AmaCerto': 'orange',
        'AmaLucia': 'pink',
        'AmaMora': 'purple',
        'AmaKristina': 'brown',
        'Amastella': 'cadetblue',
        'AmaVenita': 'cadetblue',
        'Amareina': 'cadetblue',
        '^Travelmarvel': 'cadetblue',
        '^Viking': 'steelblue',
        '^Viva': 'beige',
        '^MS Swiss Ruby': 'beige',
        '^Avalon': 'olive',
        '^Emerald': 'blueviolet',
        '^Scenic': 'blueviolet',
        '^nicko': 'yellow',
        '^MS Rhein Symphonie': 'yellow',
        '^MS Rhein Melodie': 'yellow',
        '^MS Belvedere': 'yellow',
        '^Imperial City Tour': 'violet',
        '^SS Antoinette': 'LightSalmon',
        '^River Queen': 'LightSalmon',
        '^SS Victoria': 'LightSalmon',

      };

      // Group ship schedules by date, ship, and code
      const groupByDate = {}
      shipSchedules.forEach(schedule => {
        if (!schedule.Date) {
          return // Skip schedules with missing Date property
        }
        const date = schedule.Date.substring(0, 10)
        const shipCode = `${schedule.Ship}_${schedule.Code}`
        if (!groupByDate[date]) {
          groupByDate[date] = {}
        }
        if (!groupByDate[date][shipCode]) {
          groupByDate[date][shipCode] = []
        }
        groupByDate[date][shipCode].push(schedule)
      })

      // Create events array with one event per unique ship+code per date
      const events = []
      Object.keys(groupByDate).forEach(date => {
        Object.keys(groupByDate[date]).forEach(shipCode => {
          const schedules = groupByDate[date][shipCode]
          const ship = schedules[0].Ship
          const code = schedules[0].Code
          const title = `${ship}\n${code}`
          const description = schedules.map(s => s.Name).join(', ')
          let color = 'grey';
          for (const shipRegex in shipColors) {
            const regex = new RegExp(shipRegex, 'i');
            if (regex.test(ship)) {
              color = shipColors[shipRegex];
              break;
            }
          }
          events.push({
            title,
            start: date,
            allDay: true,
            color,
            description
          })
        })
      })
      this.calendarOptions.events = events
    })
  },


  mounted() {
    this.calendarOptions.eventRender = function (info) {
      const titleParts = info.event.title.split('\n')
      const shipName = titleParts[0]
      const code = titleParts[1]
      const description = info.event.extendedProps.description
      const titleHtml = `
      <div class="fc-title"><span class="ship-name" style="font-size: 120px; font-weight: bold">${shipName}</span><br><span class="code" style="font-size: 60px; font-weight: bold">${code}</span></div>
      <div class="fc-description">${description}</div>
    `
      info.el.querySelector('.fc-content').innerHTML = titleHtml
    }
  },


  methods: {
    changeView(view) {
      this.$refs.calendar.getApi().changeView(view)
    },
    handleDateClick: function (arg) {
      alert('date click! ' + arg.dateStr)
    },
    downloadPDF(e) {
      e.preventDefault()

      const isBrowser = typeof window !== 'undefined'

      if (isBrowser) {
        const calendarEl = document.querySelector('.fc')
        const style = document.createElement('style')
        style.innerHTML = '.fc-title, .fc-description { font-size: 60px !important; }'
        calendarEl.appendChild(style)
      }

      html2canvas(document.querySelector('.fc'), {
        backgroundColor: '#ffffff',
        scale: 2
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('l', 'mm', [420, 297]) // Change width and height here

        pdf.addImage(imgData, 'JPEG', 0, 0, 420, 297) // Change width and height here
        pdf.save('calendar.pdf')

        if (isBrowser) {
          const calendarEl = document.querySelector('.fc')
          const style = calendarEl.querySelector('style')
          calendarEl.removeChild(style)
        }
      })
    }

  }
}
</script>


<style>
html,
body {
  height: 100%;
}

.fc-event-title {
  font-size: 16.7px;
}

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}
.calendar-container {
    margin-top: 150px;
}

.fc-h-event .fc-event-main {
  color: black;
}</style>