<template>
  <div>
    <!-- Margin, Compticket, Factor, and Tiers  -->
    <div class="card mt-4">
      <div class="card-header">
        <h6 class="mb-0 d-inline">Settings and Tiers </h6>
        <b-button class="btn btn-secondary mt-1" @click="addTier">+</b-button>
        <b-button class="btn btn-success mt-1" @click="saveSettings">Save</b-button>
        <b-button class="btn btn-warning mt-1" @click="clearTiers">Clear</b-button>
      </div>

      <div class="card-body">
        <div class="row">
          <!-- Compticket Input -->
          <div class="col-md-1">
            <div class="form-group">
              <label for="compticket">Compticket</label>
              <input type="number" v-model.number="compticket" class="form-control" id="compticket" step="1" />
            </div>
          </div>
          <!-- Margin Input -->
          <div class="col-md-1">
            <div class="form-group">
              <label for="margin">Margin</label>
              <input type="number" v-model.number="margin" class="form-control" id="margin" step="1" />
            </div>
          </div>
          <!-- Margin Child Input -->
          <div class="col-md-1">
            <div class="form-group">
              <label for="marginChild">Margin Child</label>
              <input type="number" v-model.number="marginChild" class="form-control" id="marginChild" step="1" />
            </div>
          </div>

          <!-- Margin EmptySeats Input -->
          <div class="col-md-1">
            <div class="form-group">
              <label for="marginEmpty">Margin Empty</label>
              <input type="number" v-model.number="marginEmpty" class="form-control" id="marginEmpty" step="1" />
            </div>
          </div>
          <!-- Factor Input -->
          <div class="col-md-1">
            <div class="form-group">
              <label for="factor">Calculation Tier</label>
              <input type="number" v-model.number="factor" class="form-control" id="factor" step="1" />
            </div>
          </div>
          <!-- Tiers Input Fields -->
          <div class="col-md-7">
            <div class="row">
              <div v-for="(tier, index) in tiers" :key="index" class="col-md-2">
                <div class="form-group">
                  <label :for="'tier-' + index">Tier {{ index + 1 }}</label>
                  <div class="input-group">
                    <input type="number" v-model.number="tier.value" :id="'tier-' + index" class="form-control" step="1"
                      placeholder="Enter tier value" />
                    <div>
                      <b-button size="sm" class="remove-btn" @click="removeTier(index)">
                        <i class="fas fa-trash"></i>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Buying Prices Table -->
    <div class="card mt-4">
      <div class="card-header">
        <h6 class="mb-0 d-inline">Buying Calculation</h6>
      </div>
      <div class="card-body">
        <div v-if="tiers.length > 0">
          <table class="table custom-table">
            <thead class="thead-dark">
              <tr>
                <th>Tier</th>
                <th v-for="(tier, index) in tiers" :key="'tier-header-' + index">{{ tier.value }}</th>
              </tr>
              <tr>
                <td>Cost per Adult</td>
                <td v-for="(tier, index) in tiers" :key="'cost-adult-calculated-' + index">
                  {{ formatPrice(calculateCost(tier, 'adult')) }}
                </td>
              </tr>
              <tr>
                <td>Cost per Child</td>
                <td v-for="(tier, index) in tiers" :key="'cost-child-calculated-' + index">
                  {{ formatPrice(calculateCost(tier, 'child')) }}
                </td>
              </tr>
              <tr>
                <td>Cost per Empty Seat</td>
                <td v-for="(tier, index) in tiers" :key="'cost-empty-seat-calculated-' + index">
                  {{ formatPrice(calculateEmptySeatCost(tier)) }}
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div v-else>
          <p>No tiers available for this tour.</p>
        </div>
      </div>
    </div>

    <!-- Selling Prices Table with Calculated and User Input Prices -->
    <div class="card mt-4">
      <div class="card-header">
        <h6 class="mb-0 d-inline">Selling Price Calculation Based on Calculation Tier = {{ factor }}</h6>
      </div>
      <div class="card-body">
        <div v-if="tiers.length > 0">
          <table class="table custom-table">
            <thead class="thead-dark">
              <tr>
                <th>Tier</th>
                <th v-for="(tier, index) in tiers" :key="'tier-header-' + index">{{ tier.value }}</th>
              </tr>
              <tr>
                <td>Suggested Price per Adult</td>
                <td v-for="(tier, index) in tiers" :key="'selling-adult-calculated-' + index">
                  {{ formatPrice(calculateSellingPriceForTier(tier, 'adult', index)?.adult)|| 'N/A' }}
                </td>
              </tr>
              <tr>
                <td>Submitted Price per Adult</td>
                <td v-for="(tier, index) in tiers" :key="'selling-adult-input-' + index">
                  <input type="number" v-model.number="userInputSellingPriceAdult[index]" class="form-control"
                    :placeholder="formatPrice(calculateSellingPriceForTier(tier, 'adult', index)?.adult) || 'N/A'"
                    step="1" />
                </td>
              </tr>
              <tr>
                <td>Suggested Price per Child</td>
                <td v-for="(tier, index) in tiers" :key="'selling-child-calculated-' + index">
                  {{ formatPrice(calculateSellingPriceForTier(tier, 'child', index)?.child) || 'N/A' }}
                </td>
              </tr>
              <tr>
                <td>Submitted Price per Child</td>
                <td v-for="(tier, index) in tiers" :key="'selling-child-input-' + index">
                  <input type="number" v-model.number="userInputSellingPriceChild[index]" class="form-control"
                    :placeholder="formatPrice(calculateSellingPriceForTier(tier, 'child', index)?.child) || 'N/A'"
                    step="1" />
                </td>
              </tr>
              <tr>
                <td>Suggested Price per Empty Seat</td>
                <td v-for="(tier, index) in tiers" :key="'selling-empty-seat-calculated-' + index">
                  {{ formatPrice(calculateSellingPriceForEmptySeatsWithDifference(tier)) }}
                </td>
              </tr>
              <tr>
                <td>Submitted Price per Empty Seat</td>
                <td v-for="(tier, index) in tiers" :key="'selling-empty-seat-input-' + index">
                  <input type="number" v-model.number="userInputSellingPriceEmptySeat[index]" class="form-control"
                    :placeholder="formatPrice(calculateSellingPriceForEmptySeatsWithDifference(tier))" step="1" />
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div v-else>
          <p>No tiers available for this tour.</p>
        </div>


      </div>
    </div>


  </div>
</template>


<script>
import { formatPrice } from '../Helper/PriceDisplay.js';

import {
  calculateCost,
  calculateSellingPriceForTier,
  calculateEmptySeatCost,
  calculateSellingPriceForEmptySeatsWithDifference
} from '../Helper/Calculation.js';

export default {
  data() {
    return {
      margin: 0,
      marginChild: 0,
      marginEmpty: 0,
      compticket: 0,
      factor: 1,
      tiers: [],
      userInputCostAdult: [],
      userInputSellingPriceAdult: [],
      userInputSellingPriceChild: [],
      userInputSellingPriceEmptySeat: []
    };
  },
  async created() {
    const tourId = this.$route.params.id;
    this.fetchTour(tourId);
  },
  methods: {
    formatPrice,
    fetchTour(tourId) {
      this.$xhr.get(`/api/tours/${tourId}?populate=*`)
        .then(response => {
          this.tour = response.data.data;
          this.margin = this.tour.attributes.margin || 0;
          this.marginChild = this.tour.attributes.marginChild || 0;
          this.marginEmpty = this.tour.attributes.marginEmpty || 0;
          this.compticket = this.tour.attributes.compticket || 0;
          this.factor = this.tour.attributes.factor || 1;
          this.tiers = this.tour.attributes.tiers
            ? this.tour.attributes.tiers.map(tier => ({
              value: tier.value,
              userInputSellingPriceAdult: tier.userInputSellingPriceAdult,
              userInputSellingPriceChild: tier.userInputSellingPriceChild,
              userInputSellingPriceEmptySeat: tier.userInputSellingPriceEmptySeat
            }))
            : [];
          this.userInputSellingPriceAdult = this.tiers.map(tier => tier.userInputSellingPriceAdult);
          this.userInputSellingPriceChild = this.tiers.map(tier => tier.userInputSellingPriceChild);
          this.userInputSellingPriceEmptySeat = this.tiers.map(tier => tier.userInputSellingPriceEmptySeat);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateCost(tier, type) {
      return calculateCost(this.tour, tier, this.compticket, this.factor, type);
    },
    calculateSellingPriceForTier(tier, type, index) {
      return calculateSellingPriceForTier(tier, this.tour, this.factor, this.margin, this.marginChild, index);
    },
    calculateEmptySeatCost(tier) {
      return calculateEmptySeatCost(this.tour, tier, this.factor);
    },
    calculateSellingPriceForEmptySeatsWithDifference(tier) {
      return calculateSellingPriceForEmptySeatsWithDifference(tier, this.tour, this.factor, this.marginEmpty, this.marginChild);
    },
    addTier() {
      this.tiers.push({
        value: 0,
        userInputSellingPriceAdult: '',
        userInputSellingPriceChild: '',
        userInputSellingPriceEmptySeat: ''
      });
      this.userInputSellingPriceAdult;
      this.userInputSellingPriceChild;
      this.userInputSellingPriceEmptySeat;
    },
    removeTier(index) {
      this.tiers.splice(index, 1);
      this.userInputSellingPriceAdult.splice(index, 1);
      this.userInputSellingPriceChild.splice(index, 1);
      this.userInputSellingPriceEmptySeat.splice(index, 1);
    },
    clearTiers() {
      this.tiers = [];
      this.userInputSellingPriceAdult = [];
      this.userInputSellingPriceChild = [];
      this.userInputSellingPriceEmptySeat = [];
    },
    saveSettings() {
      // Update tiers array with user input values
      this.tiers = this.tiers.map((tier, index) => ({
        ...tier,
        userInputSellingPriceAdult: this.userInputSellingPriceAdult[index],
        userInputSellingPriceChild: this.userInputSellingPriceChild[index],
        userInputSellingPriceEmptySeat: this.userInputSellingPriceEmptySeat[index]
      }));

      const updatedData = {
        margin: this.margin,
        marginChild: this.marginChild,
        marginEmpty: this.marginEmpty,
        compticket: this.compticket,
        factor: this.factor,
        tiers: this.tiers
      };

      this.$xhr.put(`/api/tours/${this.tour.id}`, { data: updatedData })
        .then(() => {
          this.$bvToast.toast('Settings saved successfully!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000
          });
        })
        .catch(() => {
          this.$bvToast.toast('Failed to save settings.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          });
        });
    }

  }
};
</script>


<style scoped>
.card {
  margin-bottom: 1rem;
}

.card-header {
  background-color: #f7f7f7;
  padding: 0.75rem;
}

.card-body {
  padding: 1rem;
}

.table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  text-align: center;
}


.table input.form-control {
  border-radius: 0.5rem;
  font-weight: bold;
  text-align: center;

}

.table input.form-control::placeholder {
  font-weight: normal;
}

.custom-table thead.thead-dark th {
  background-color: #E2923D;
  color: #fff;

}


.btn {
  cursor: pointer;
  margin-top: 1rem;
  margin-inline: 2px;
}


.remove-btn {
  background-color: red;
  color: white;
  margin-left: 1rem;
  margin-top: 3px;
}
</style>
