export function calculateCost(tour, tierValueObj, compticket, factor, type, useFactor = false) {
  const tierValue = useFactor ? factor : tierValueObj.value;
  if (!tour.attributes.item || tour.attributes.item.data.length === 0) return 0;

  const priceType = type === 'child' ? 'childPrice_gross' : 'adultPrice_gross';

  // Handle itemFactors, default to empty object if undefined
  const itemFactors = tour.attributes.itemFactor || {};

  // Calculate per person sum
  const perPersonSum = tour.attributes.item.data
    .filter(item => item.attributes.type === 'per person')
    .reduce((acc, item) => acc + item.attributes[priceType], 0);

  // Calculate bulk sum with itemFactor
  const bulkSum = tour.attributes.item.data
    .filter(item => item.attributes.type === 'bulk')
    .reduce((acc, item) => {
      const itemFactor = itemFactors[item.id] || 1; // Default to 1 if factor is not present
      return acc +  itemFactor * item.attributes.price;
    }, 0);

  // Calculate hourly sum with itemFactor
  const hourlySum = tour.attributes.item.data
    .filter(item => item.attributes.type === 'hourly')
    .reduce((acc, item) => {
      const itemFactor = itemFactors[item.id] || 1; // Default to 1 if factor is not present
      return acc +itemFactor * item.attributes.price;
    }, 0);

  return ((tierValue + compticket) * perPersonSum + bulkSum + hourlySum) / tierValue;
}



export function calculateSellingPrice(bp, margin, marginChild, isChild = false) {
  if (isChild) {
    return bp + (bp * 100 / (100 - margin * ((marginChild) / 100)) - bp) * 1.19;
  } else {
    return bp + (bp * 100 / (100 - margin) - bp) * 1.19;
  }
}

export function calculateSellingPriceForTier(tier, tour, factor, margin, marginChild, index) {
  // Adult Calculations
  // Step 1: Calculate buying price for adults at the current tier
  const currentTierBuyingPriceAdult = calculateCost(tour, tier, tour.attributes.compticket, factor, 'adult');

  // Step 2: Calculate buying price for adults at the special tier
  const specialTierBuyingPriceAdult = calculateCost(tour, { value: factor }, tour.attributes.compticket, factor, 'adult', true);

  // Step 3: Calculate selling price for adults at the special tier
  const specialTierSellingPriceAdult = calculateSellingPrice(specialTierBuyingPriceAdult, margin, marginChild, false);

  // Step 4: Calculate the difference between special tier selling price and buying price for adults
  const differenceAdult = specialTierSellingPriceAdult - specialTierBuyingPriceAdult;

  // Step 5: Calculate the selling price for adults at the current tier
  const sellingPriceAdult = currentTierBuyingPriceAdult + differenceAdult;

  // Child Calculations
  // Step 1: Calculate buying price for children at the current tier
  const currentTierBuyingPriceChild = calculateCost(tour, tier, tour.attributes.compticket, factor, 'child');

  // Step 2: Calculate buying price for children at the special tier
  const specialTierBuyingPriceChild = calculateCost(tour, { value: factor }, tour.attributes.compticket, factor, 'child', true);

  // Step 3: Calculate selling price for children at the special tier
  const specialTierSellingPriceChild = calculateSellingPrice(specialTierBuyingPriceChild, margin, marginChild, true);

  // Step 4: Calculate the difference between special tier selling price and buying price for children
  const differenceChild = specialTierSellingPriceChild - specialTierBuyingPriceChild;

  // Step 5: Calculate the selling price for children at the current tier
  const sellingPriceChild = currentTierBuyingPriceChild + differenceChild;

  return {
    adult: sellingPriceAdult,
    child: sellingPriceChild
  };
}



export function calculateEmptySeatCost(tour, tierValueObj, factor) {
  const tierValue = tierValueObj ? tierValueObj.value : factor;
  if (!tour.attributes.item || tour.attributes.item.data.length === 0) return 0;

  const bulkSum = tour.attributes.item.data
    .filter(item => item.attributes.type !== 'per person')
    .reduce((acc, item) => {
      const itemFactor = tour.attributes.itemFactor[item.id] || 1;
      return acc + item.attributes.price * itemFactor ;
    }, 0);

  return bulkSum / tierValue;
}


export function calculateSellingPriceForEmptySeat(tier, tour, marginEmpty, marginChild) {
  const bpEmptySeat = calculateEmptySeatCost(tour, tier, tour.attributes.factor);
  return bpEmptySeat + (((bpEmptySeat * 100) / (100 - (marginEmpty * (marginChild / 100))) - bpEmptySeat)) * 1.19;
}

export function calculateSellingPriceForEmptySeatsWithDifference(tier, tour, factor, marginEmpty, marginChild) {
  // Step 1: Calculate buying price for empty seats at the current tier
  const currentTierBuyingPriceForEmptySeat = calculateEmptySeatCost(tour, tier, factor);

  // Step 2: Calculate buying price for empty seats at the special tier
  const specialTierBuyingPriceForEmptySeat = calculateEmptySeatCost(tour, { value: factor }, factor);

  // Step 3: Calculate selling price for empty seats at the special tier
  const specialTierSellingPriceForEmptySeat = calculateSellingPriceForEmptySeat({ value: factor }, tour, marginEmpty, marginChild);

  // Step 4: Calculate the difference between special tier selling price and buying price
  const difference = specialTierSellingPriceForEmptySeat - specialTierBuyingPriceForEmptySeat;

  // Step 5: Calculate the selling price for empty seats at the current tier
  return currentTierBuyingPriceForEmptySeat + difference;
}
