<template>
  <div>
    <div class="d-flex align-items-center mb-3" style="margin-top: 2%;">
      <b-form-select v-model="filters.year" :options="yearOptions" class="mr-3" placeholder="Year" />
      <b-form-select v-model="filters.city" :options="cityOptions" class="mr-3" placeholder="City" />
      <b-form-select v-model="filters.item" :options="itemOptions" class="mr-3" placeholder="Item" />
      <h5 class="mr-3">Services ({{ filteredServices.length }})</h5>
      <b-form-select v-model="filters.catagory" :options="catagoryOptions" class="mr-3" placeholder="Catagory" />
      <b-form-select v-model="filters.supplier" :options="supplierOptions" class="mr-3" placeholder="Supplier" />
      <b-form-input v-model="searchQuery" class="mr-4" placeholder="Search..." />
      <b-button class="addbtn" variant="primary" @click="showForm = true; currentService = null">Add Service</b-button>
    </div>

    <div class="table-responsive">
      <table class="table table-striped custom-table">
        <thead class="thead-dark">
          <tr>
            <th><input type="checkbox" @change="selectAll" /></th>
            <th>Item</th>
            <th>Year</th>
            <th>City</th>
            <th>Adult Price (Gross)</th>
            <th>Child Price (Gross)</th>
            <th>Adult Price (Net)</th>
            <th>Child Price (Net)</th>
            <th>Price (Gross)</th>
            <th>Type</th>
            <th>Tax</th>
            <th>Tax Rate</th>
            <th>Category</th>
            <th>Remarks</th>
            <th>Cancellation</th>
            <th>Supplier</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in filteredServices" :key="service.id"
            @contextmenu.prevent="showContextMenu($event, service)" @click="toggleRowSelection(service.id)"
            :class="{ 'highlight': service.isSelected }" @mouseenter="highlightRow(service, true)"
            @mouseleave="highlightRow(service, false)">
            <td><input type="checkbox" v-model="selectedServices" :value="service.id" /></td>
            <td>{{ service.attributes.item }}</td>
            <td>{{ service.attributes.year }}</td>
            <td>
              <ul>
                <li v-for="city in service.attributes.cities.data" :key="city.id">
                  {{ city.attributes.city }}
                </li>
              </ul>
            </td>
            <td>{{ formatPrice(service.attributes.adultPrice_gross) }}</td>
            <td>{{ formatPrice(service.attributes.childPrice_gross) }}</td>
            <td>{{ formatPrice(service.attributes.adultPrice_net) }}</td>
            <td>{{ formatPrice(service.attributes.childPrice_net) }}</td>
            <td>{{ formatPrice(service.attributes.price) }}</td>
            <td>{{ service.attributes.type }}</td>
            <td>{{ service.attributes.tax }}</td>
            <td>{{ service.attributes.rate }}%</td>
            <td>{{ service.attributes.catagory }}</td>
            <td>{{ service.attributes.remarks }}</td>
            <td>{{ service.attributes.cancellation }}</td>
            <td>{{ service.attributes.supplier }}</td>
          </tr>
        </tbody>
      </table>


    </div>

    <!-- Context Menu -->
    <div v-if="contextMenuVisible" :style="{ top: `${contextMenuPosition.y}px`, left: `${contextMenuPosition.x}px` }"
      class="context-menu">
      <ul>
        <li @click="editService(contextMenuService)">Edit</li>
        <li @click="confirmDelete(contextMenuService)">Delete</li>
        <li @click="promptCloneService(contextMenuService)">Clone</li>
      </ul>
    </div>

    <ServiceForm v-if="showForm" :service="currentService" :cities="cities"
      @close="showForm = false; currentService = null" @service-added="fetchServices" />

    <!-- Clone Service Modal -->
    <b-modal v-model="showCloneModal" title="Clone Service" @ok="cloneService">
      <b-form-row class="align-items-center">
        <!-- Item Input -->
        <b-col class="col-8">
          <b-form-group label="Item:" label-for="item-input" class="mb-0">
            <b-form-input id="item-input" v-model="cloneItem" type="text" placeholder="Enter item name" class="w-100" />
          </b-form-group>
        </b-col>

        <!-- Year Input -->
        <b-col class="col-4">
          <b-form-group label="Year:" label-for="year-input" class="mb-0">
            <b-form-input id="year-input" v-model="newYear" type="number" placeholder="Enter year" class="w-100" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-modal>

    <!-- Delete Confirmation Modal -->
    <b-modal v-model="showDeleteModal" title="Confirm Deletion" @ok="deleteSelectedServices">
      <p>Are you sure you want to delete the selected service(s)? This action cannot be undone.</p>
    </b-modal>

    <!-- Toast Notifications -->
    <b-toast id="success-toast" title="Success" variant="success" solid>Service cloned successfully!</b-toast>
    <b-toast id="error-toast" title="Error" variant="danger" solid>Failed to clone service. Please try again.</b-toast>
    <b-toast v-model="toastVisible" id="feedback-toast" :title="toastTitle" :variant="toastVariant" solid>{{
      toastMessage }}</b-toast>

  </div>
</template>


<script>
import ServiceForm from './ServiceForm.vue';
import { formatPrice } from '../Helper/PriceDisplay.js';

export default {
  components: {
    ServiceForm
  },
  data() {
    return {
      services: [],
      selectedServices: [],
      showForm: false,
      showCloneModal: false,
      currentService: null,
      newYear: '',
      cloneItem: '',
      toastVisible: false,
      toastTitle: '',
      toastMessage: '',
      toastVariant: '',
      searchQuery: '',
      filters: {
        year: '',
        city: '',
        item: '',
        supplier: '',
        catagory:''
      },
      cities: [],
      contextMenuVisible: false,
      contextMenuPosition: { x: 0, y: 0 },
      contextMenuService: null
    };
  },
  async created() {
    await this.fetchServices();
    await this.fetchCities();
  },
  methods: {
    formatPrice,
    async fetchServices() {
      const response = await this.$xhr.get('/api/services?populate=*');
      this.services = response.data.data.map(service => ({
        ...service,
        attributes: {
          ...service.attributes,
          city: service.attributes.city || { data: { attributes: { city: 'Unknown' } } }
        },
        isSelected: false  // Track hover state
      }));
    },

    async fetchCities() {
      const response = await this.$xhr.get('/api/cities');
      this.cities = response.data.data;
    },
    showContextMenu(event, service) {
      this.contextMenuVisible = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
      this.contextMenuService = service;
    },
    hideContextMenu() {
      this.contextMenuVisible = false;
      this.contextMenuService = null;
    },
    editService(service) {
      this.hideContextMenu();
      this.currentService = service;
      this.showForm = true;
    },
    confirmDelete() {
      this.showDeleteModal = true;
    },
    async deleteSelectedServices() {
      await Promise.all(
        this.selectedServices.map(serviceId => this.$xhr.delete(`/api/services/${serviceId}`))
      );
      this.fetchServices();
      this.selectedServices = [];
      this.showDeleteModal = false;
      this.showToast('Success', 'Item deleted successfully!', 'success');
    },
    showToast(title, message, variant) {
      this.toastTitle = title;
      this.toastMessage = message;
      this.toastVariant = variant;
      this.toastVisible = true;
    },
    promptCloneService(service) {
      this.hideContextMenu();
      this.currentService = service;
      this.cloneItem = service.attributes.item; // Prefill the item field
      this.newYear = service.attributes.year; // Prefill year
      this.showCloneModal = true;
    },
    closeCloneModal() {
      this.showCloneModal = false;
      this.newYear = '';
      this.cloneItem = '';
    },
    async cloneService() {
      if (this.newYear && this.cloneItem) {
        const newYear = String(this.newYear);
        const cloneItem = this.cloneItem;

        try {
          // Check for existing service
          const existingServiceResponse = await this.$xhr.get(`/api/services?filters[item][$eq]=${encodeURIComponent(cloneItem)}&filters[year][$eq]=${encodeURIComponent(newYear)}`);

          if (existingServiceResponse.data.data.length > 0) {
            this.$bvToast.toast('This service already exists for the specified year.', {
              title: 'Clone Aborted',
              variant: 'danger',
              solid: true,
            });
            return;
          }

          // Prepare cloned service data
          const clonedService = { ...this.currentService };
          clonedService.attributes.year = newYear;
          clonedService.attributes.item = cloneItem;
          clonedService.id = null; // Ensure the cloned service gets a new ID

          // Include cities in the cloned service
          const cityIds = this.currentService.attributes.cities?.data.map(city => city.id) || [];
          const requestData = {
            data: {
              ...clonedService.attributes,
              cities: cityIds.length > 0 ? cityIds.map(id => ({ id })) : undefined,
            },
          };

          // Post the cloned service
          await this.$xhr.post('/api/services', requestData);
          this.fetchServices();
          this.closeCloneModal();
          this.$bvToast.toast('Service cloned successfully!', {
            title: 'Success',
            variant: 'success',
            solid: true,
          });
        } catch (error) {
          console.error('Error cloning service:', error);
          this.$bvToast.toast('An error occurred while cloning the service.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast('Please enter both item name and a new year.', {
          title: 'Warning',
          variant: 'warning',
          solid: true,
        });
      }
    },
    selectAll(event) {
      this.selectedServices = event.target.checked ? this.filteredServices.map(service => service.id) : [];
    },
    toggleRowSelection(serviceId) {
      const index = this.selectedServices.indexOf(serviceId);
      if (index === -1) {
        this.selectedServices.push(serviceId);
      } else {
        this.selectedServices.splice(index, 1);
      }
    },
    highlightRow(service, isHovered) {
      service.isSelected = isHovered;
    },
  },
  computed: {
    yearOptions() {
      return [{ value: '', text: 'Year' }, ...this.sortedUniqueYears.map(year => ({ value: year, text: year }))];
    },
    cityOptions() {
      return [{ value: '', text: 'City' }, ...this.sortedUniqueCities.map(city => ({ value: city, text: city }))];
    },
    itemOptions() {
      return [{ value: '', text: 'Item' }, ...this.sortedUniqueItems.map(item => ({ value: item, text: item }))];
    },
    supplierOptions() {
      return [{ value: '', text: 'Supplier' }, ...this.sortedUniqueSuppliers.map(supplier => ({ value: supplier, text: supplier }))];
    },
    catagoryOptions() {
      return [{ value: '', text: 'Catagory' }, ...this.sortedUniqueCatagories.map(catagory => ({ value: catagory, text: catagory }))];
    },
    sortedUniqueYears() {
      return [...new Set(this.services.map(service => service.attributes.year))]
        .sort((a, b) => a - b);
    },
    sortedUniqueCities() {
      return [...new Set(this.services.flatMap(service => service.attributes.cities.data.map(city => city.attributes.city)))]
        .sort();
    },
    sortedUniqueItems() {
      return [...new Set(this.services.map(service => service.attributes.item))]
        .sort();
    },
    sortedUniqueSuppliers() {
      return [...new Set(this.services.map(service => service.attributes.supplier))]
        .sort();
    },
    sortedUniqueCatagories() {
      return [...new Set(this.services.map(service => service.attributes.catagory))]
        .sort();
    },
    filteredServices() {
      const searchQueryLower = this.searchQuery.toLowerCase();

      return this.services.filter(service => {
        const cities = service.attributes.cities.data.map(city => city.attributes.city.toLowerCase());
        const remarks = service.attributes.remarks || '';
        const cancellation = service.attributes.cancellation || '';
        const category = service.attributes.catagory || '';

        return (
          (!this.filters.year || service.attributes.year == this.filters.year) &&
          (!this.filters.city || cities.includes(this.filters.city.toLowerCase())) &&
          (!this.filters.item || service.attributes.item.toLowerCase().includes(this.filters.item.toLowerCase())) &&
          (!this.filters.supplier || service.attributes.supplier.toLowerCase().includes(this.filters.supplier.toLowerCase())) &&
          (!this.filters.catagory || service.attributes.catagory.toLowerCase().includes(this.filters.catagory.toLowerCase())) &&
          (
            searchQueryLower === '' ||
            service.attributes.item.toLowerCase().includes(searchQueryLower) ||
            String(service.attributes.year).toLowerCase().includes(searchQueryLower) ||
            cities.some(city => city.includes(searchQueryLower)) ||
            remarks.toLowerCase().includes(searchQueryLower) ||
            cancellation.toLowerCase().includes(searchQueryLower) ||
            category.toLowerCase().includes(searchQueryLower) ||
            service.attributes.supplier.toLowerCase().includes(searchQueryLower)
          )
        );
      });
    }

  },
  mounted() {
    document.addEventListener('click', this.hideContextMenu);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideContextMenu);
  }
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.context-menu {
  position: fixed;
  background-color: #e6e0e0;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 150px;
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 10px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #edc99b;
}

.custom-table {
  width: 100%;
  table-layout: auto;
  margin-top: 6%;
  max-height: 740px;
  overflow-y: auto;
  display: block;
}

.table-responsive {
  overflow-y: auto;
}

.table th,
.table td {
  width: 0.1%;
  text-align: center;

}

.table th {
  background-color: #343a40;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.d-flex {
  display: flex;
  position: fixed;
}

.align-items-center {
  align-items: center;
}

.mb-3 {
  margin-bottom: 1rem;
  margin-top: 2%;
  margin-left: 1.4%;
}

.mr-3 {
  max-width: 14rem;
}

.mr-4 {
  margin-right: 1rem;
  max-width: 18rem;
  margin-inline: 2rem;
}

.addbtn {
  margin-top: 5rem;
  margin-left: 5rem;
}

.table tbody tr.highlight {
  background-color: #edc99b;
}
</style>
