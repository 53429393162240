<template>
    <Header />

    <div class="home">
        <img class="logo" alt="Vue logo" src="@lib/frontend/assets/new.png">
        <h1>Welcome to the Prebooking Overview tool </h1>
    </div>

    

    <footer class="main-foot footer">
        <div class="content-container">
            <div class="foot-wrap">
                <div class="foot-line">
                    <nav>
                        <ul class="foot-nav">
                            <a href="https://time-change.com/code-of-conduct-eng/">Code of Conduct</a><br>
                            <a href="https://time-change.com/privacy-policy/">Privacy policy</a><br>
                            <a href="https://time-change.com/imprint/">Imprint</a> <br>
                            <img class='insidelogo' src="@lib/frontend/assets/tc.png" alt="alt">

                        </ul>
                    </nav>
                </div>
                <div class="foot-line">
                    <div class="city-links-wrap">
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/amsterdam_en/" class="city-name">amsterdam</a>
                            <a href="tel:+31202248310" class="city-tel">+31 20 224831 0</a>
                            <a href="mailto:amsterdam@time-change.com" class="city-mail">amsterdam@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/berlin_en/" class="city-name">berlin</a>
                            <a href="tel.: +49 30 12076646 0" class="city-tel">+49 30 12076646 0</a>
                            <a href="mailto:berlin@time-change.com" class="city-mail">berlin@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/cologne/" class="city-name">cologne</a>
                            <a href="tel:+4930120766460 " class="city-tel">+49 30 12076646 0</a>
                            <a href="mailto:cologne@time-change.com " class="city-mail">cologne@time-change.com</a>
                        </div>

                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/copenhagen_en/" class="city-name">copenhagen</a>
                            <a href="tel:+45 50 381782" class="city-tel">+45 50 381782</a>
                            <a href="mailto:copenhagen@time-change.com" class="city-mail">copenhagen@time-change.com</a>
                        </div>

                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/frankfurt_en/" class="city-name">frankfurt</a>
                            <a href="tel:+49 69 1532426 0" class="city-tel">+49 69 1532426 0</a>
                            <a href="mailto:frankfurt@time-change.com" class="city-mail">frankfurt@time-change.com</a>
                        </div>

                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/hamburg_en/" class="city-name">hamburg</a>
                            <a href="tel:+49 40 2576718 0" class="city-tel">+49 40 2576718 0</a>
                            <a href="mailto:hamburg@time-change.com" class="city-mail"> hamburg@time-change.com</a>
                        </div>

                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/london_en/" class="city-name">london</a>
                            <a href="tel:+49 0 7309 76372 1" class="city-tel">+49 0 7309 76372 1</a>
                            <a href="mailto:london@time-change.com" class="city-mail">london@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/munich/" class="city-name">munich</a>
                            <a href="tel:+49 89 8091193 0" class="city-tel">+49 89 8091193 0</a>
                            <a href="mailto:munich@time-change.com" class="city-mail">munich@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/prag_en/" class="city-name">prague</a>
                            <a href="tel:+420 77682960 0" class="city-tel">+420 77682960 0</a>
                            <a href="mailto:prague@time-change.com" class="city-mail">prague@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/rostock_en/" class="city-name">rostock</a>
                            <a href="tel:+4930120766460 " class="city-tel">+49 30 12076646 0</a>
                            <a href="mailto:rostock@time-change.com " class="city-mail">rostock@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/vienna/" class="city-name">vienna</a>
                            <a href="tel:+43 1 361866 0" class="city-tel">+43 1 361866 0</a>
                            <a href="mailto:vienna@time-change.com" class="city-mail">vienna@time-change.com</a>
                        </div>
                        <div class="city-link">
                            <a href="http://dev.time-change.com/standorte/zurich-2/" class="city-name">Zurich</a>
                            <a href="tel:+41 44 789261 0 " class="city-tel">+41 44 789261 0</a>
                            <a href="mailto:zurich@time-change.com" class="city-mail">zurich@time-change.com</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import {inject} from "vue";

export default {
    name: 'Homeview',
    components: {
        Header,
    },
    setup(){
        const {profile} = inject('user')
        return {
            profile
        }
    },
    mounted() {
        let user = localStorage.getItem('user-info');
        if (!user) {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>
.logo {
    height: 180px;
    margin-top: 150px;
}

.content-container {
    background-color: black;
    color: white;
    font-size: 40px;
    margin-top: 500px;
    height: 900px;
    width: auto;
}

.city-links-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: white;
}

.city-link {
    width: calc(25% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    font-size: 30px;
}

.city-mail {
    color: white;
}

.city-name {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    margin-bottom: 5px;
}

.city-name:hover {
    text-decoration: underline;
}

.city-tel {
    font-size: 16px;
    text-decoration: none;
    color: #666;
}

.city-tel:hover {
    color: #333;
    text-decoration: underline;
}

.follow-us {
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-left: 900px;
}

.social-link-wrap {
    display: flex;
}

.social-link {
    margin-right: 10px;

}

.social-link img {
    height: 30px;
    width: auto;
}

.insidelogo {
    margin-left: 500px;
    max-height: 100px;
    max-width: auto;
    margin-top: -300px;
}

nav a {
    color: white;

}
</style>
